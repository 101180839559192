<template>
  <div id="home">
    <!-- TODO: Enable profile check for student site -->
    <!-- Check if the profile is complete -->
    <!-- <v-container v-if="!userData.username" class="mt-12">
      <ProfileAlertRow />
    </v-container> -->
    <v-container>
      <ContentRow :content="options.create" />
      <div class="my-6 my-md-12"></div>
      <ContentRow :content="options.manage" />
      <div class="my-6 my-md-12"></div>
      <ContentRow :content="options.view" />
      <div class="my-6 my-md-12"></div>
    </v-container>
  </div>
</template>

<script>
//import ProfileAlertRow from "@/components/Home/Home/ProfileAlertRow.vue";
import ContentRow from "@/components/Home/Home/ContentRow.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "Admin Home",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Admin Home Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: {
    //ProfileAlertRow,
    ContentRow,
  },
  data: () => ({
    loading: true,
    userData: {},
    options: {
      create: {
        title: "Create",
        icon: "mdi-plus-box-multiple",
        colors: ["blue darken-4", "blue darken-1", "blue darken-2"],
        options: [
          {
            title: "New Question",
            subTitle: "Add to the question bank.",
            to: "/create/question",
            image: "undraw_publish_post_vowb.svg",
          },
          {
            title: "New Test",
            subTitle: "Create and deploy a new test.",
            to: "/create/test",
            image: "undraw_File_bundle_xl7g.svg",
          },
          {
            title: "New PDF",
            subTitle: "Add a PDF to existing group.",
            to: "/create/pdf",
            image: "undraw_New_entries_re_cffr.svg",
          },
        ],
      },
      manage: {
        title: "Manage",
        icon: "mdi-playlist-edit",
        colors: ["pink darken-1", "pink lighten-1", "pink darken-1"],
        options: [
          {
            title: "Edit Tests",
            subTitle:
              "Edit existing tests (add/remove questions, visibility, timing etc.)",
            to: "/manage/edit-tests",
            image: "undraw_profile_details_f8b7.svg",
          },
          {
            title: "Question Bank",
            subTitle:
              "Edit and view existing questions (folders, solution etc.)",
            to: "/manage/question-bank",
            image: "undraw_collecting_fjjl.svg",
          },
          {
            title: "Courses",
            subTitle: "Add and manage Courses (test groups etc.)",
            to: "/manage/courses",
            image: "undraw_Bookshelves_re_lxoy.svg",
          },
          {
            title: "PDFs",
            subTitle: "Manage PDF groups and folders",
            to: "/manage/pdfs",
            image: "undraw_Responsive_re_e1nn.svg",
          },
        ],
      },
      view: {
        title: "View",
        icon: "mdi-cards",
        colors: ["green darken-1", "green lighten-1", "green darken-1"],
        options: [
          {
            title: "Results",
            subTitle:
              "Test Results with student ranking, score and other data.",
            to: "/view/results",
            image: "undraw_resume_1hqp.svg",
          },
          {
            title: "Discount Code Sales",
            subTitle: "Number of successful sales made via a discount code.",
            to: "/view/code-sales",
            image: "undraw_Site_stats_re_ejgy.svg",
          },
          {
            title: "Contact Form",
            subTitle: "Entries from the contact form on the landing page",
            to: "/view/contact-form",
            image: "undraw_Popular_re_mlfe.svg",
          },
        ],
      },
    },
  }),
  mounted() {
    this.userData = this.$store.getters.userData;
    this.loading = false;
  },
};
</script>
